<template>
    <div>        
        <input type="text" class="position-fixed top-0 left-0"  style="display: none; color: #fff; z-index: 99999; opacity: 0;" id="infoInputCopy"/>
        <div class="d-flex flex-column" :style="cardHeight">
            <div class="d-flex flex-fill flex-grow-1" style="height: 100px;" >                
                <!-- /.card-header -->
                <div class="flex-fill d-flex flex-column card-body p-1 pt-0" v-loading="isLoading">
                    <div>
                        <div>
                            <div class="mb-1"><h4><strong>Software display name</strong></h4></div>
                        </div>
                    </div>
                    <VuePerfectScrollbar class="table-responsive p-0 flex-fill"
                                         :settings="{ useBothWheelAxes: false, suppressScrollX: true }">
                        <div v-if="softwareInstalled.length > 0" 
                               class="text-nowrap">
                            <div class="row">
                                <div :key="element.id" v-for="element in softwareInstalled" 
                                     class="col-12 col-md-4 pb-0 pt-1">
                                    <el-card :body-style="{ padding: '0.5rem' }">
                                        <div class="d-flex align-items-center">
                                            <div>
                                                <img :src="getIconUrl(element.installedSoftwares.displayName)" style="width: 2rem;" 
                                                      onerror="this.src='/img/software.svg'"/>
                                            </div>
                                            <div class="ml-3" style="line-height: 1rem;">
                                                <div><strong>{{element.installedSoftwares.displayName}}</strong></div>
                                                <div><small>{{element.installedSoftwares.version}}</small></div>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </div>
                        </div>
                        <div v-else class="nodata-container flex-fill d-flex align-items-center justify-content-center h-100">
                            <div class="align-self-center text-center">
                                <div class="mb-5"><img src="/static/image/no-job-icon.svg" style="opacity: 0.7;"></div>
                                <h4><strong class="text-muted">There is no Software has been added</strong></h4>
                                <p class="text-muted">Software of this machine show here !</p>
                            </div>
                        </div>
                    </VuePerfectScrollbar>
                </div>
                <!-- /.card-body -->
            </div>
        <!-- /.card -->

        </div>        

    </div>
</template>


<script>
    import vmMachineApi from "@/api/gpuHub/vmMachines";
    import baseComponent from "@/scripts/baseComponent";
    const iconAppUrl = {
        "Redshift": "/img/software-icon/Redshift.png",
        "NVIDIA": "/img/software-icon/NVIDIA.png",
        "Intel": "/img/software-icon/Intel(R) Chipset Device Software.png",
        "TeamViewer": "/img/software-icon/TeamViewer.png",
        "anydesk": "/img/software-icon/anydesk.png",
        "ultraviewer": "/img/software-icon/ultraviewer.png",
        "Cinema 4D": "/img/software-icon/cinema-4d.png",
        "maxon app": "/img/software-icon/maxon app.svg",
        "octane": "/icon/OctaneRender.svg",
        "Dropbox": "/img/software-icon/Dropbox.png",
        "AMD": "/img/software-icon/amd.png",
        "iRender": "/img/software-icon/iRender.png",
        "Realtek": "/img/software-icon/Realtek.jpg",
        "Autodesk":"/img/software-icon/Autodesk.png",
        "7-Zip":"/img/software-icon/7-Zip.svg",
        "asus":"/img/software-icon/asus.svg",
        "Epic Game":"/img/software-icon/Epic-Games.svg"
    };

    export default {
        extends: baseComponent,
        props: {
            cardHeight: {
                type: String,
                default: 'height: calc(100dvh - 5.5rem);'
            },
            detailMachineItem: {
                type: Object,
                default: null,
            },
        },
        watch: {
            detailMachineItem(newVal) {
                console.log("detailMachineItem", newVal);
                if (this.detailMachineItem !== null) {
                    this.getSoftwareInstalledByMachineId();
                }
            },
        },
        data() {
            return {
                softwareInstalled: [],
                iconAppUrl: iconAppUrl,
                iconAppKeys: Object.keys(iconAppUrl),
            };
        },
        mounted() {
            this.getSoftwareInstalledByMachineId();
        },
        methods: {
            getSoftwareInstalledByMachineId() {
                vmMachineApi
                    .getSoftwareInstalledByMachineId(this.detailMachineItem.vmMachineId)
                    .then((response) => {
                        if (
                            response.data &&
                            response.data.result === 0 &&
                            response.data.data !== null
                        ) {
                            console.log("getSoftwareInstalledByMachineId", response.data.data);
                            this.$set(this, "softwareInstalled", response.data.data);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            getIconUrl(softwareName) {
                let iconKey = this.iconAppKeys.find(x => softwareName.toLowerCase().includes(x.toLowerCase()));
                if (iconKey) return this.iconAppUrl[iconKey];
                else return "/img/software.svg";
            },
        },
    }
</script>